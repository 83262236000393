html {
  @apply h-full;
}
@layer components {
  .default-wrapper {
    @apply max-w-5xl px-5 md:px-12 lg:px-16 w-full mx-auto;
  }

  .simple-wrapper {
    @apply max-w-3xl px-5 md:px-12 lg:px-16 w-full mx-auto;
  }
}

.trangle-edges {
  clip-path: polygon(
    8px 0,
    calc(100% - 8px) 0,
    100% 50%,
    calc(100% - 8px) 100%,
    8px 100%,
    0 50%
  );
}

.pagination {
  @apply flex items-center gap-1;

  .swiper-pagination-bullet {
    @apply w-3 h-3 bg-white transform rotate-45 rounded-none opacity-50 transition;

    &.swiper-pagination-bullet-active,
    &:hover {
      @apply opacity-100;
    }
  }
}

.appear-in,
.appear-item {
  @apply opacity-0;
}

.freeform-form .main-column-class ul.errors li {
  @apply hidden last:block;
}

.plyr.plyr--video .plyr__control.plyr__control--overlaid[data-plyr="play"] {
  @apply bg-white scale-150 origin-center -translate-x-1/2 -translate-y-1/2;

  svg {
    @apply fill-off-black;
  }

  &:hover {
    @apply bg-blackish;
  }
}

.main-column-class.text-spread {
  @apply flex items-center justify-between;
}
