.navigation {
}

.menu-button {
  @apply w-8 h-5;

  .line {
    @apply w-full absolute right-0 transform bg-blackish transition-all duration-150;
    height: 2px;
    border-radius: 1.5px;

    &.line-1 {
      @apply top-0;
    }

    &.line-2 {
      @apply top-1/2 -translate-y-1/2;
    }

    &.line-3 {
      @apply top-full -translate-y-full;
    }
  }

  /* &:hover {
    @media (hover: hover) {
      .line {
        &.line-1 {
          @apply top-1/2 w-1/3 rotate-45;
          --tw-translate-y: -200%;
        }

        &.line-3 {
          @apply top-1/2 translate-y-full w-1/3 -rotate-45;
        }
      }
    }
  } */

  &.open {
    .line {
      @apply sm:bg-white;

      &.line-1 {
        @apply top-1/2 w-full -translate-y-1/2 rotate-45;
      }

      &.line-2 {
        @apply opacity-0;
      }

      &.line-3 {
        @apply top-1/2 w-full -translate-y-1/2 -rotate-45;
      }
    }
  }
}
