@layer components {
  .h1-text {
    @apply font-goldman text-[1.625rem] sm:text-4xl font-normal leading-none;
    letter-spacing: 0.36px;
  }
  .h1-sm-text {
    @apply font-goldman text-[1.5625rem] sm:text-3xl lg:text-[2rem] font-normal leading-normal;
  }
  .h2-text {
    @apply font-goldman text-xl sm:text-2xl lg:text-3xl font-normal leading-tight;
  }
  .modat-title-xl-text {
    @apply font-inter font-bold text-[1.375rem] lg:text-[1.625rem] leading-none;
  }
  .big-number-text {
    @apply font-inter font-normal text-2xl leading-relaxed;
    letter-spacing: -0.96px;
  }
  .modal-title-text {
    @apply font-inter font-bold text-lg sm:text-xl lg:text-[1.375rem] leading-none;
  }
  .h4-text {
    @apply font-goldman font-normal text-base sm:text-[1.375rem] leading-snug;
  }
  .h3-text {
    @apply font-goldman text-base sm:text-xl font-normal leading-snug;
    letter-spacing: 0.2px;
  }
  .h5-text {
    @apply font-inter text-base sm:text-lg lg:text-xl font-normal leading-normal;
  }
  .ingress-text {
    @apply font-inter text-[.9375rem] lg:text-lg font-normal leading-normal;
  }
  .body-xl-text {
    @apply font-inter text-[.9375rem] sm:text-base lg:text-lg leading-snug;
  }
  .body-xl-med-text {
    @apply font-inter text-base sm:text-[1.0625rem] font-medium leading-snug;
  }
  .body-text {
    @apply font-inter text-[.9375rem] sm:text-base lg:text-[1.0625rem] font-normal leading-normal;
  }
  .button-text {
    @apply font-inter text-sm sm:text-base font-bold leading-normal;
  }
  .body-med-text {
    @apply font-inter text-sm sm:text-base font-medium leading-normal;
  }
  .menu-text {
    @apply font-goldman text-base font-normal leading-normal;
  }
  .placeholder-text {
    @apply font-inter text-base font-normal leading-none;
  }
  .button-sm-text {
    @apply font-inter text-[.9375rem] font-bold leading-relaxed;
    letter-spacing: 0.9px;
  }
  .list-text {
    @apply font-inter text-[.9375rem] font-medium leading-relaxed;
  }
  .status-text {
    @apply font-inter text-sm font-black leading-relaxed;
    letter-spacing: 1.4px;
  }
  .pretitle-text {
    @apply font-inter text-sm font-bold leading-relaxed;
    letter-spacing: 1.4px;
  }
  .body-sm-semi-text {
    @apply font-inter text-sm font-semibold leading-tight;
  }
  .body-sm-text {
    @apply font-inter text-xs sm:text-sm font-normal sm:font-medium leading-snug;
  }
  .status-text-mob {
    @apply font-inter text-[.8125rem] font-black leading-relaxed;
    letter-spacing: 1.3px;
  }
  .button-xs-text {
    @apply font-inter text-xs lg:text-[.8125rem] font-bold leading-relaxed;
    letter-spacing: 0.78px;
  }
  .label-text {
    @apply font-inter text-[.8125rem] font-semibold leading-relaxed;
  }
  .small-text {
    @apply font-inter text-xs font-medium leading-normal;
  }
}
