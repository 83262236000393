.wysiwyg {
  h2 {
    @apply mb-2 mt-6 md:mb-4 md:mt-16 font-goldman h2-text leading-tight text-white;

    &:last-child {
      @appy mb-0;
    }

    & strong {
      @apply font-bold;
    }
  }

  h3 {
    @apply mb-3 mt-6 h3-text text-white;
  }

  h4 {
    @apply mb-4 modal-title-text text-white h5-text;
  }

  p {
    @apply mb-6;

    &.mb-2 {
      @apply mb-2;
    }
  }

  & > *:last-child {
    @apply mb-0;
  }

  &.small {
    strong {
      @apply font-semibold;
    }
  }

  strong {
    @apply font-black;
  }

  a:not(.button) {
    background-image: linear-gradient(
      90deg,
      currentColor 0%,
      currentColor 100%
    );
    background-size: 0% 2px;
    background-position: right bottom;
    background-repeat: no-repeat;
    transition: background-size 300ms ease;

    &:hover {
      background-position: left bottom;
      background-size: 100% 2px;
    }
  }

  ul,
  ol {
    margin: 1em 1.5em;
    @apply leading-normal my-[1em] mx-[1.5em];
  }

  ul {
    @apply list-disc;
  }

  ol {
    @apply list-decimal;
  }

  hr {
    @apply border-off-black my-6;
  }

  a:not(.button) {
    @apply text-off-white font-medium hover:text-cool-grey tracking-wide;

    width: calc(100%);
    background-image: linear-gradient(
      transparent calc(100% - 1px),
      currentColor 1px
    );
    background-position: right top;
    background-repeat: no-repeat;
    background-size: 0% 100%;
    transition: background-size 0.3s, color 0.3s;
    text-decoration: none !important;

    &:hover {
      background-position: left top;
      background-size: 100% 100%;
    }
  }

  strong {
    a {
      @apply font-black;
    }
  }
}
